import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import axios, { isCancel, AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CreateIcon from "@mui/icons-material/Create";
import Loader from "@components/Loader";

export const serviceOrderModal = (data) => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });
  const [open, setOpen] = useState(false);
  const [openConfirmActionBox, setOpenConfirmActionBox] = useState(false);
  const [openConfirmValidate, setOpenConfirmValidate] = useState(false);
  const [openConfirmExit, setOpenConfirmExit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [textBoxSize, setTextBoxSize] = useState(4);
  const [reviseText, setReviseText] = useState("");
  const [validateBox, setValidateBox] = useState(false);
  const [confirmActionBox, setConfirmActionBox] = useState(false);
  const [serviceOrderData, setServiceOrderData] = useState({
    data: "",
    hora_inicio: "",
    hora_fim: "",
    id: "",
    itens: "",
    protocolo: "",
    status: "",
  });

  useEffect(() => {
    if (window.innerHeight < 520) setTextBoxSize(1);
    if (window.innerHeight >= 520) setTextBoxSize(4);
  }, [window.innerHeight]);

  const handleOpen = () => {
    setOpen(true);
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://portal.gruppe.com.br/cliente/aprovacao/ordem-de-servico/${data.props.itemID}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setServiceOrderData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const aproveOS = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `https://portal.gruppe.com.br/cliente/ordem-de-servico/valida/${data.props.itemID}/A`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setIsLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const validateOS = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `https://portal.gruppe.com.br/cliente/ordem-de-servico/valida/${data.props.itemID}/R`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      body: {
        mensagem: reviseText,
      },
    })
      .then((response) => {
        setIsLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const handleClose = () => {
    setOpenConfirmActionBox(false);
    setOpenConfirmValidate(false);
    setOpenConfirmExit(false);
    data.setReRender(true);
    setValidateBox(false);
    setOpenConfirmActionBox(false);
    setOpen(false);
  };

  const statusTitle = (param) => {
    switch (param) {
      case 0:
        return "Recusada";
      case 1:
        return "Em Aberto";
      case 2:
        return "Encerrada";
      case 3:
        return "Aprovada";
      case 4:
        return "Aguardando Aprovação do Gestor";
      case 5:
        return "Com Periodo Aprovado";
      default:
        return "";
    }
  };

  const confirmAprovalBox = () => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openConfirmActionBox}
          onClose={() => setOpenConfirmActionBox(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openConfirmActionBox}>
            <div className="modal-confirm-container">
              <div className="modal-confirm-content">
                <div className="modal-header">
                  <h2>Deseja aprovar a ordem de serviço?</h2>
                </div>
                <div className="modal-confirmation-buttons">
                  <button
                    className="modal-confirmation-aprove"
                    onClick={aproveOS}
                  >
                    Aprovar
                  </button>
                  <button
                    className="modal-confirmation-cancel"
                    onClick={() => setOpenConfirmActionBox(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const confirmValidateBox = () => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openConfirmValidate}
          onClose={() => setOpenConfirmValidate(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openConfirmValidate}>
            <div className="modal-confirm-container">
              <div className="modal-confirm-content">
                <div className="modal-header">
                  <h2>Deseja enviar para revisão a ordem de serviço?</h2>
                </div>
                <div className="modal-confirmation-buttons">
                  <button
                    className="modal-confirmation-aprove"
                    onClick={validateOS}
                  >
                    Confirmar
                  </button>
                  <button
                    className="modal-confirmation-cancel"
                    onClick={() => setOpenConfirmValidate(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const confirmExitBox = () => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openConfirmExit}
          onClose={() => setOpenConfirmExit(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openConfirmExit}>
            <div className="modal-confirm-container">
              <div className="modal-confirm-content">
                <div className="modal-header">
                  <h2>
                    Você ainda tem uma mensagem não enviada, deseja realmente
                    sair?
                  </h2>
                </div>
                <div className="modal-confirmation-buttons">
                  <button
                    className="modal-confirmation-aprove"
                    onClick={handleClose}
                  >
                    Sair
                  </button>
                  <button
                    className="modal-confirmation-cancel"
                    onClick={() => setOpenConfirmExit(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <Button title="Aprovar" sx={{ minWidth: "0px" }} onClick={handleOpen}>
        <CreateIcon />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className="modal-container">
            <div className="modal-content">
              {isLoading && <Loader />}
              {confirmAprovalBox()}
              {confirmValidateBox()}
              {confirmExitBox()}
              <button
                className="exit-button"
                onClick={() =>
                  reviseText == "" ? handleClose() : setOpenConfirmExit(true)
                }
              >
                X
              </button>
              <div className="modal-header">
                <h2>Ordem de Serviço: {data.props.serviceOrderProtocol}</h2>
                <h2>Status: {statusTitle(data.props.serviceOrderStatus)}</h2>
                <span />
              </div>
              <hr style={{ marginInline: "30px" }} />
              <div className="modal-info">
                <div className="info-box">
                  <p>
                    Cliente:{" "}
                    {data.props.serviceOrderClient
                      ? data.props.serviceOrderClient
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Consultor:{" "}
                    {data.props.serviceOrderConsultant
                      ? data.props.serviceOrderConsultant
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Total Horas:{" "}
                    {serviceOrderData.total_horas
                      ? serviceOrderData.total_horas
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Quilometragem:{" "}
                    {serviceOrderData.km ? serviceOrderData.km : "0"}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Data: {serviceOrderData.data ? serviceOrderData.data : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Almoço:{" "}
                    {serviceOrderData.valor_almoco
                      ? serviceOrderData.valor_almoco
                      : "0,00"}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Outras Despesas:{" "}
                    {serviceOrderData.valor_outros
                      ? serviceOrderData.valor_outros
                      : "0,00"}
                  </p>
                </div>
              </div>
              <div className="table-container">
                <table className="listing-table">
                  <thead>
                    <tr>
                      <th className="listing-table-th">Centro de Custo</th>
                      <th className="listing-table-th">Tipo de atendimento</th>
                      <th className="listing-table-th">Tempo</th>
                      <th className="listing-table-th">ID Cliente</th>
                      <th className="listing-table-th">Projeto</th>
                      <th className="listing-table-th">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceOrderData.itens ? (
                      serviceOrderData.itens.map((item) => {
                        return (
                          <tr>
                            <td>
                              {item.centro_de_custo.descricao
                                ? item.centro_de_custo.descricao
                                : ""}
                            </td>
                            <td>
                              {item.tipo_atendimento.descricao
                                ? item.tipo_atendimento.descricao
                                : ""}
                            </td>
                            <td>{item.tempo.valor ? item.tempo.valor : ""}</td>
                            <td>{item.id ? item.id : ""}</td>
                            <td>
                              {item.centro_de_custo.descricao
                                ? item.centro_de_custo.descricao
                                : ""}
                            </td>
                            <td>
                              {item.descricao.valor ? item.descricao.valor : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div>carregando...</div>
                    )}
                  </tbody>
                </table>
              </div>
              {validateBox ? (
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      marginTop: "30px",
                      width: "-webkit-fill-available",
                      marginLeft: "20px",
                      marginRight: "20px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="outlined-multiline-static"
                      label="Mensagem"
                      multiline
                      rows={textBoxSize}
                      onChange={(e) => setReviseText(e.target.value)}
                      value={reviseText}
                    />
                  </div>
                </Box>
              ) : (
                ""
              )}
              {validateBox ? (
                <div className="modal-buttons">
                  <button
                    className="modal-button-aprove"
                    onClick={() => setOpenConfirmValidate(true)}
                  >
                    Enviar para revisão
                  </button>
                  <button
                    className="modal-button-cancel"
                    onClick={() => setValidateBox(false)}
                  >
                    Voltar
                  </button>
                </div>
              ) : (
                <div className="modal-buttons">
                  <button
                    className="modal-button-aprove"
                    onClick={() => setOpenConfirmActionBox(true)}
                  >
                    Aprovar
                  </button>
                  <button
                    className="modal-button-revise"
                    onClick={() => setValidateBox(true)}
                  >
                    Revisar
                  </button>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default serviceOrderModal;
