import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loader = () => {
  return (
    <Box sx={{}}>
      <CircularProgress sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
      }} />
    </Box>
  );
};

export default Loader;
