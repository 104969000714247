import { Fragment, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import { Helmet } from "@components/index";
import Home from "./home/Index.jsx";
import Schedule from "./schedule/Index.jsx";
import WorkOrder from "./workorder/Index.jsx";
import Report from "./reportPage/Index.jsx";
import Login from "./login/Index.jsx";
import ForgotPassword from "./forgotPassword/Index.jsx";
import Profile from "./profile/Index.jsx";
import { tokenValidation } from "@services/index.js";
import { Topbar } from "@components/index.js";

const Index = () => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });

  const navigate = useNavigate();

  useEffect(() => {
    // tokenValidation() ? "" : navigate("/login");
  }, []);

  return (
    <Fragment>
      <Helmet />
      {tokenValidation() ? (
        <Topbar>
          <Routes>
            <Route path="/dashboard" element={<Home />} />
            <Route path="/agenda" element={<Schedule />} />
            <Route path="/ordens-de-serviço" element={<WorkOrder />} />
            <Route path="/relatorio" element={<Report />} />
            <Route path="/perfil" element={<Profile />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Topbar>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Fragment>
  );
};

export default Index;
