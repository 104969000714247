import { ScrollToTop } from "@components/index";
import Pages from "./pages/Index.jsx";

const App = () => {
  return (
    <ScrollToTop>
      <Pages />
    </ScrollToTop>
  );
};

export default App;
