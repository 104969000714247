const logo = `${process.env.assetsDomain}/assets/images/logo.svg`;

/**
 * This is a sample component
 * @param {string=} props.param1 This is a string param
 * @param {array=} props.param2 This array contains other data
 * @param {string=} props.className This param contains css props
 */
export const Example = ({ param1 = "", param2 = [], className }) => {
  return (
    <div className={className}>
      <h1 className="margin-bottom-40">{param1}</h1>
      <img src={logo} alt="Logo" />
    </div>
  );
};
