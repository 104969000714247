import { useState, useEffect, useMemo } from "react";
import Pagination from "@services/pagination";
import ServiceOrderModal from "@components/ServiceOrderModal";
import ServiceOrderModalVizualize from "@components/ServiceOrderModalVizualize";
import { useNavigate } from "react-router-dom";
import axios, { isCancel, AxiosError } from "axios";
import { tokenValidation, dateValidation } from "@services/index.js";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Loader from "@components/Loader";
import MultiSelectDropdown from "@components/MultiSelectDropdown";

const options = [
  { title: "Recusada", value: 0 },
  { title: "Em Aberto ", value: 2 },
  // { title: "Encerrada", value: 2 },
  { title: "Aprovada", value: 3 },
  // { title: "Aguardando Aprovação do Gestor", value: 4 },
  // { title: "Com Periodo Aprovado", value: 5 },
];

const WorkOrder = () => {
  const [serviceOrderData, setServiceOrderData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [reRenderCurrentPage, setReRenderCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? "" : navigate("/login");
    if (dateValidation() == true) {
      setTimeout(() => {
        alert(
          "Seu token expirou, por favor faça login novamente no Portal Gruppe",
        );
        localStorage.removeItem("userData");
        navigate("/login");
      }, 1000);
    }
    setIsLoading(true);
    axios({
      method: "get",
      url: "https://portal.gruppe.com.br/cliente/aprovacao/ordens-de-servico",
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setServiceOrderData(response.data);
        console.log(
          "🚀 ~ file: Index.jsx:65 ~ .then ~ response.data:",
          response.data,
        );
        setCurrentPage(1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
        setIsLoading(false);
        if (error.response.data.message == "Token has expired") {
          alert(
            "Seu token expirou, por favor faça login novamente no Portal Gruppe",
          );
          console.log("asdasdasd");
        }
      });
  }, []);

  const statusIcon = (param) => {
    switch (param) {
      case 0:
        return <ThumbDownOffAltIcon sx={{ color: "red" }} />;
      case 1:
        return "";
      case 2:
        return <EditCalendarIcon sx={{ color: "gold" }} />;
      case 3:
        return <ThumbUpOffAltIcon sx={{ color: "green" }} />;
      case 4:
        return <PendingActionsIcon sx={{ color: "red" }} />;
      case 5:
        return <EventAvailableIcon sx={{ color: "green" }} />;
      default:
        return "";
    }
  };

  const statusTitle = (param) => {
    switch (param) {
      case 0:
        return "Recusada";
      case 1:
        return "Encerrado";
      case 2:
        return "Em Aberto";
      case 3:
        return "Aprovada";
      case 4:
        return "Aguardando Aprovação do Gestor";
      case 5:
        return "Com Periodo Aprovado";
      default:
        return "";
    }
  };

  let PageSize = 10;

  const [filterPagination, setFilterPagination] = useState([]);

  const currentTableData = useMemo(() => {
    if (currentPage == "triggerRender") setCurrentPage(reRenderCurrentPage);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (filterPagination && filterPagination.length > 0) {
      return filterPagination.slice(firstPageIndex, lastPageIndex);
    } else {
      return serviceOrderData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage]);

  const makeLoadingTrue = () => setIsLoading(true);

  if (reRender == true) {
    makeLoadingTrue;
    axios({
      method: "get",
      url: "https://portal.gruppe.com.br/cliente/aprovacao/ordens-de-servico",
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setServiceOrderData(response.data);
        setReRender(false);
        setReRenderCurrentPage(currentPage);
        setFilterPagination(
          response.data.filter((item) => {
            return selected.some((filteredItem) => {
              return filteredItem == item.status_cliente;
            });
          }),
        );
        setCurrentPage("triggerRender");
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
        setReRender(false);
        if (error.response.data.message == "Token has expired") {
          alert(
            "Seu token expirou, por favor faça login novamente no Portal Gruppe",
          );
          localStorage.removeItem("userData");
          navigate("/login");
        }
      });
  }

  const [selected, setSelected] = useState([]);
  const [teste, setTeste] = useState(false);

  useEffect(() => {
    if (selected.length > 0) {
      const filteredOSData = serviceOrderData.filter((item) => {
        return selected.some((filteredItem) => {
          return filteredItem == item.status_cliente;
        });
      });
      setFilterPagination(filteredOSData);
      setCurrentPage("triggerRender");
    }
    if (!teste) {
      setTeste(true);
    } else {
      if (selected.length <= 0) {
        setFilterPagination(serviceOrderData);
        setCurrentPage("triggerRender");
      }
    }
  }, [selected]);

  const toggleOption = ({ value }) => {
    setSelected((prevSelected) => {
      const newArray = [...prevSelected];
      if (newArray.includes(value)) {
        return newArray.filter((item) => item != value);
      } else {
        newArray.push(value);
        return newArray;
      }
    });
  };

  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h2 style={{ fontSize: "20px", marginTop: "10px", marginBottom: "20px" }}>
        Ordens de Serviço a Aprovar
      </h2>
      {serviceOrderData ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  {" "}
                  <MultiSelectDropdown
                    options={options}
                    selected={selected}
                    toggleOption={toggleOption}
                  />
                </th>
                <th>Protocolo</th>
                <th>Cliente</th>
                <th>Consultor</th>
                <th>Ações</th>
              </tr>
            </thead>
            {serviceOrderData.length > 0 ? (
              ""
            ) : (
              <div
                style={{
                  textAlign: "",
                  marginTop: "30px",
                  marginLeft: "10px",
                  fontSize: "20px",
                }}
              >
                Nenhuma ordem de serviço disponível
              </div>
            )}
            <tbody>
              {selected.length > 0
                ? currentTableData.map((item, index) => {
                    return selected
                      .filter(
                        (filterStatus) => filterStatus == item.status_cliente,
                      )
                      .map(() => {
                        return (
                          <tr>
                            <td
                              key={index}
                              title={statusTitle(item.status_cliente)}
                              style={{ paddingLeft: "30px" }}
                            >
                              {statusIcon(item.status_cliente)}
                            </td>
                            <td key={index + 1}>{item.protocolo}</td>
                            <td key={index + 2}>{item.cliente}</td>
                            <td key={index + 3}>{item.consultor}</td>
                            <div style={{ display: "flex" }}>
                              {item.status_cliente == 3 ? (
                                <ServiceOrderModalVizualize
                                  props={{
                                    itemID: item.id,
                                    serviceOrderClient: item.cliente,
                                    serviceOrderConsultant: item.consultor,
                                    serviceOrderStatus: item.status_cliente,
                                    serviceOrderProtocol: item.protocolo,
                                    minWidht: false,
                                  }}
                                  setReRender={setReRender}
                                />
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <ServiceOrderModalVizualize
                                    props={{
                                      itemID: item.id,
                                      serviceOrderClient: item.cliente,
                                      serviceOrderConsultant: item.consultor,
                                      serviceOrderStatus: item.status_cliente,
                                      serviceOrderProtocol: item.protocolo,
                                      minWidht: true,
                                    }}
                                    setReRender={setReRender}
                                  />
                                  <ServiceOrderModal
                                    props={{
                                      itemID: item.id,
                                      serviceOrderClient: item.cliente,
                                      serviceOrderConsultant: item.consultor,
                                      serviceOrderStatus: item.status_cliente,
                                      serviceOrderProtocol: item.protocolo,
                                    }}
                                    setReRender={setReRender}
                                  />
                                </div>
                              )}
                            </div>
                          </tr>
                        );
                      });
                  })
                : currentTableData.map((item, index) => {
                    return (
                      <tr>
                        <td
                          key={index}
                          title={statusTitle(item.status_cliente)}
                          style={{ paddingLeft: "30px" }}
                        >
                          {statusIcon(item.status_cliente)}
                        </td>
                        <td key={(index = 1)}>{item.protocolo}</td>
                        <td key={(index = 2)}>{item.cliente}</td>
                        <td key={(index = 3)}>{item.consultor}</td>
                        <div style={{ display: "flex" }}>
                          {item.status_cliente == 3 ? (
                            <ServiceOrderModalVizualize
                              props={{
                                itemID: item.id,
                                serviceOrderClient: item.cliente,
                                serviceOrderConsultant: item.consultor,
                                serviceOrderStatus: item.status_cliente,
                                serviceOrderProtocol: item.protocolo,
                                minWidht: true,
                              }}
                              setReRender={setReRender}
                            />
                          ) : (
                            <div style={{ display: "flex" }}>
                              <ServiceOrderModalVizualize
                                props={{
                                  itemID: item.id,
                                  serviceOrderClient: item.cliente,
                                  serviceOrderConsultant: item.consultor,
                                  serviceOrderStatus: item.status_cliente,
                                  serviceOrderProtocol: item.protocolo,
                                  minWidth: true,
                                }}
                                setReRender={setReRender}
                              />
                              <ServiceOrderModal
                                props={{
                                  itemID: item.id,
                                  serviceOrderClient: item.cliente,
                                  serviceOrderConsultant: item.consultor,
                                  serviceOrderStatus: item.status_cliente,
                                  serviceOrderProtocol: item.protocolo,
                                }}
                                setReRender={setReRender}
                              />
                            </div>
                          )}
                        </div>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {selected.length > 0 ? (
            filterPagination.length > 0 ? (
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={filterPagination.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  fontSize: "20px",
                }}
              >
                Nenhum item neste filtro
              </div>
            )
          ) : (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={serviceOrderData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      ) : (
        <div>carregando</div>
      )}
    </div>
  );
};

export default WorkOrder;
