import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { isCancel, AxiosError } from "axios";
import { tokenValidation } from "@services/index.js";
import Loader from "@components/Loader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
        isSignedIn: "",
      }
    );
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? navigate("/dashboard") : navigate("/login");
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    // data: {
    //   user: "testeapp@gruppe.com.br",
    //   password: "123456",
    // },
    setIsLoading(true);
    axios({
      method: "post",
      url: "https://portal.gruppe.com.br/api/login",
      data: {
        user: email,
        password: password,
      },
    })
      .then((response) => {
        getUserData(response.data.token);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
        setErrorMessage(true);
        setIsLoading(false);
      });
  };

  const getUserData = (responseToken) => {
    setIsLoading(true);
    axios({
      method: "get",
      url: "https://portal.gruppe.com.br/restricted/user",
      headers: {
        Authorization: `Bearer ${responseToken}`,
      },
    })
      .then((response) => {
        console.log("--------------------------------------------------------");
        console.log("response = ", response);
        console.log("--------------------------------------------------------");
        setIsLoading(false);
        setUserData({
          token: responseToken,
          email: response.data.email,
          nome: response.data.nome,
          expirationDate: new Date(),
          permissoes: "",
          entidade_ativa: "",
          entidades: [],
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
    userData.token ? navigate("/dashboard") : "";
  }, [userData]);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form
            className="login100-form validate-form"
            onSubmit={(e) => handleLogin(e)}
          >
            <span className="login100-form-title p-b-43">Portal Gruppe</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <input
                className={`input100 ${email ? "has-val" : ""}`}
                value={email}
                name="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                  setErrorMessage(false);
                }}
                required
              ></input>
              <span className="focus-input100"></span>
              <span className="label-input100">Usuário</span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              {isLoading && <Loader />}
              <input
                className={`input100 ${password ? "has-val" : ""}`}
                value={password}
                name="pass"
                type="password"
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setErrorMessage(false);
                }}
                required
              ></input>
              <span className="focus-input100"></span>
              <span className="label-input100">Senha</span>
            </div>

            <div className="flex-sb-m w-full p-t-3 p-b-32">
              <div className="contact100-form-checkbox">
                <label className="error-message">
                  {errorMessage ? "Usuário e/ou senha inválidos" : ""}
                </label>
              </div>
              <div>
                <Link
                  to="/forgot-password"
                  title="Voltar"
                  style={{}}
                  className="txt1"
                >
                  Esqueceu a senha?
                </Link>
              </div>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Login
              </button>
            </div>
          </form>

          <div className="left-half">
            <img
              src="../../assets/images/logos gruppe (2).png"
              alt="Gruppe Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
