import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Topbar = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (onclick) => {
    if (onclick == "perfil") navigate("/perfil");
    if (onclick == "sair") {
      localStorage.removeItem("userData");
      navigate("/login");
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />

          </IconButton> */}
          <a href="https://gruppe.com.br/" target="_blank" title="Site Gruppe">
            <img
              src="../../assets/images/cropped-favicon-192x192.png"
              alt="Gruppe Logo"
              style={{ width: "22px", marginRight: "20px", marginLeft: "-5px" }}
            />
          </a>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Bem vindo, {userData.nome}!
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem onClick={() => handleClose("perfil")}>Perfil</MenuItem>
              <MenuItem onClick={() => handleClose("sair")}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
          <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItem key={"Inicio"} disablePadding>
              <Link
                to="/dashboard"
                title="Inicio"
                style={{ display: "content", width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Inicio"} />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItem key={"agenda"} disablePadding>
              <Link
                to="/agenda"
                title="Agenda"
                style={{ display: "content", width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <CalendarMonthIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Agenda"} />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItem key={"ordens-de-serviço"} disablePadding>
              <Link
                to="/ordens-de-serviço"
                title="Ordens de Serviço"
                style={{ display: "content", width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Ordens de Serviço"} />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItem key={"ordens-de-serviço"} disablePadding>
              <Link
                to="/relatorio"
                title="Relatório"
                style={{ display: "content", width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AssessmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Relatório"} />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
            <Divider />
            <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <ListItem key={"instagram-gruppe"} disablePadding>
                <a
                  href="https://www.instagram.com/gruppetecnologia/"
                  target="_blank"
                  title="Instagram"
                  style={{ display: "content", width: "100%" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InstagramIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Instagram"} />
                  </ListItemButton>
                </a>
              </ListItem>
            </List>
            <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <ListItem key={"linkedin-gruppe"} disablePadding>
                <a
                  href="https://www.linkedin.com/company/gruppe-tecnologia"
                  target="_blank"
                  title="Linkedin"
                  style={{ display: "content", width: "100%" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <LinkedInIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Linkedin"} />
                  </ListItemButton>
                </a>
              </ListItem>
            </List>
            <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <ListItem key={"portal-gruppe"} disablePadding>
                <a
                  href="https://gruppe.movidesk.com/Account/Login"
                  target="_blank"
                  title="Ajuda Portal Gruppe"
                  style={{ display: "content", width: "100%" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <HelpOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Portal Gruppe"} />
                  </ListItemButton>
                </a>
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
};
