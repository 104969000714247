import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import axios, { isCancel, AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Loader from "@components/Loader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export const serviceOrderModalVizualize = (data) => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });
  const [open, setOpen] = useState(false);
  const [openVizualize, setOpenVizualize] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [textBoxSize, setTextBoxSize] = useState(4);
  const [reviseText, setReviseText] = useState("");
  const [validateBox, setValidateBox] = useState(false);
  const [serviceOrderData, setServiceOrderData] = useState({
    data: "",
    hora_inicio: "",
    hora_fim: "",
    id: "",
    itens: "",
    protocolo: "",
    status: "",
  });

  useEffect(() => {
    if (window.innerHeight < 520) setTextBoxSize(1);
    if (window.innerHeight >= 520) setTextBoxSize(4);
  }, [window.innerHeight]);

  const handleOpen = () => {
    setOpen(true);
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://portal.gruppe.com.br/cliente/aprovacao/ordem-de-servico/${data.props.itemID}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setServiceOrderData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const aproveOS = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `https://portal.gruppe.com.br/cliente/ordem-de-servico/valida/${data.props.itemID}/A`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setIsLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const validateOS = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `https://portal.gruppe.com.br/cliente/ordem-de-servico/valida/${data.props.itemID}/R`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      body: {
        mensagem: reviseText,
      },
    })
      .then((response) => {
        setIsLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        console.error("Error:", error.response.data);
      });
  };

  const handleClose = () => {
    data.setReRender(true);
    setValidateBox(false);
    setOpen(false);
  };

  const statusTitle = (param) => {
    switch (param) {
      case 0:
        return "Recusada";
      case 1:
        return "Em Aberto";
      case 2:
        return "Encerrada";
      case 3:
        return "Aprovada";
      case 4:
        return "Aguardando Aprovação do Gestor";
      case 5:
        return "Com Periodo Aprovado";
      default:
        return "";
    }
  };

  return (
    <div>
      <Button
        title="Vizualizar"
        sx={data.props.minWidth ? { minWidth: "0px" } : { minWidth: "64px" }}
        onClick={handleOpen}
      >
        <VisibilityOutlinedIcon />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className="modal-container">
            <div className="modal-content">
              {isLoading && <Loader />}
              <button className="exit-button" onClick={handleClose}>
                X
              </button>
              <div className="modal-header">
                <h2>Ordem de Serviço: {data.props.serviceOrderProtocol}</h2>
                <h2>Status: {statusTitle(data.props.serviceOrderStatus)}</h2>
                <span />
              </div>
              <hr style={{ marginInline: "30px" }} />
              <div className="modal-info">
                <div className="info-box">
                  <p>
                    Cliente:{" "}
                    {data.props.serviceOrderClient
                      ? data.props.serviceOrderClient
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Consultor:{" "}
                    {data.props.serviceOrderConsultant
                      ? data.props.serviceOrderConsultant
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Total Horas:{" "}
                    {serviceOrderData.total_horas
                      ? serviceOrderData.total_horas
                      : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Quilometragem:{" "}
                    {serviceOrderData.km ? serviceOrderData.km : "0"}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Data: {serviceOrderData.data ? serviceOrderData.data : ""}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Almoço:{" "}
                    {serviceOrderData.valor_almoco
                      ? serviceOrderData.valor_almoco
                      : "0,00"}
                  </p>
                </div>
                <div className="info-box">
                  <p>
                    Outras Despesas:{" "}
                    {serviceOrderData.valor_outros
                      ? serviceOrderData.valor_outros
                      : "0,00"}
                  </p>
                </div>
              </div>
              <div className="table-container">
                <table className="listing-table">
                  <thead>
                    <tr>
                      <th className="listing-table-th">
                        Centro de Custo{data.props.modalHeight}
                      </th>
                      <th className="listing-table-th">Tipo de atendimento</th>
                      <th className="listing-table-th">Tempo</th>
                      <th className="listing-table-th">ID Cliente</th>
                      <th className="listing-table-th">Projeto</th>
                      <th className="listing-table-th">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceOrderData.itens ? (
                      serviceOrderData.itens.map((item) => {
                        return (
                          <tr>
                            <td>
                              {item.centro_de_custo.descricao
                                ? item.centro_de_custo.descricao
                                : ""}
                            </td>
                            <td>
                              {item.tipo_atendimento.descricao
                                ? item.tipo_atendimento.descricao
                                : ""}
                            </td>
                            <td>{item.tempo.valor ? item.tempo.valor : ""}</td>
                            <td>{item.id ? item.id : ""}</td>
                            <td>
                              {item.centro_de_custo.descricao
                                ? item.centro_de_custo.descricao
                                : ""}
                            </td>
                            <td>
                              {item.descricao.valor ? item.descricao.valor : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div>carregando...</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default serviceOrderModalVizualize;
