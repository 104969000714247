/**
 * This function takes a optional param to return it
 * @param {string=} param this is param
 */

const getUserData = () => {
  const saved = localStorage.getItem("userData");
  const initialValue = JSON.parse(saved);
  return (
    initialValue || {
      token: "",
      email: "",
      nome: "",
      permissoes: "",
      expirationDate: "",
      entidade_ativa: "",
      entidades: [],
    }
  );
}

 const isTimeDifferenceGreaterThanOneHour = () => {
    const timeDifferenceMilliseconds = Math.abs(getUserData().expirationDate - new Date());
    const oneHourMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds

    return timeDifferenceMilliseconds > oneHourMilliseconds;
  };


const dateValidation = () => {
  return isTimeDifferenceGreaterThanOneHour()
};

export { dateValidation };
