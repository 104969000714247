import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { isCancel, AxiosError } from "axios";
import { tokenValidation } from "@services/index.js";

const ForgotPassword = () => {
  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem("email");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [password, setPassword] = useState(() => {
    const saved = localStorage.getItem("password");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
        isSignedIn: "",
      }
    );
  });
  const [SendMessage, setSendMessage] = useState(false);
  const [responsePassword, setResponsePassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? navigate("/dashboard") : navigate("/forgot-password");
  }, []);

  const handleForgotPassword = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "https://portal.gruppe.com.br/forgot-password",
      data: {
        email: email,
      },
    })
      .then((response) => {
        setResponsePassword(response);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
        setSendMessage(true);
      });
  };

  useEffect(() => {
    localStorage.setItem("email", JSON.stringify(email));
  }, [email]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
    userData.token ? navigate("/dashboard") : "";
  }, [userData]);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form
            className="login100-form validate-form"
            onSubmit={(e) => handleForgotPassword(e)}
          >
            <span className="login100-form-title p-b-43">Portal Gruppe</span>
            <h2 style={{ marginBottom: "10px" }}>Esqueceu a senha?</h2>

            <div
              className="wrap-input100 validate-input"
              data-validate="Por favor digite um email valido, exempli: ex@abc.xyz"
            >
              <input
                className={`input100 ${email ? "has-val" : ""}`}
                value={email}
                name="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                  setSendMessage(false);
                }}
                required
              ></input>
              <span className="focus-input100"></span>
              <span className="label-input100">E-mail</span>
            </div>

            <div className="flex-sb-m w-full p-t-3 p-b-32">
              <div className="contact100-form-checkbox">
                <label className="sent-message">
                  {responsePassword ? responsePassword.data : ""}
                </label>
              </div>
              <div>
                <Link
                  to="/login"
                  title="Voltar"
                  style={{ paddingRight: "4px" }}
                >
                  Voltar
                </Link>
              </div>
            </div>

            <div className="container-login100-form-btn">
              <button
                className="login100-form-btn"
                type="submit"
                // onClick={(e) => handleForgotPassword(e)}
              >
                Recuperar a senha
              </button>
              {/* <button
                className="login100-form-btn"
                onClick={(e) => getUserData(e)}
              >
                Data
              </button> */}
            </div>
          </form>

          <div className="left-half">
            <img
              src="../../assets/images/logos gruppe (2).png"
              alt="Gruppe Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
