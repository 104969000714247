import React, { useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { Link, useNavigate } from "react-router-dom";
import axios, { isCancel, AxiosError } from "axios";
import { tokenValidation, dateValidation } from "@services/index.js";
import { TextField, Button, DialogActions } from "@mui/material";
import { pt } from "date-fns/locale";

const Schedule = () => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });
  const [scheduleData, setScheduleData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? "" : navigate("/login");
    if (dateValidation() == true) {
      setTimeout(() => {
        alert(
          "Seu token expirou, por favor faça login novamente no Portal Gruppe",
        );
        localStorage.removeItem("userData");
        navigate("/login");
      }, 1000);
    }
  }, []);

  const arrangeArray = (data) => {
    function addHours(date, hours) {
      date.setHours(date.getHours() + hours);
      return date;
    }
    const outputArray = [];
    for (const dateKey in data) {
      if (data.hasOwnProperty(dateKey)) {
        const consultants = data[dateKey];
        for (const consultantName in consultants) {
          if (consultants.hasOwnProperty(consultantName)) {
            const events = consultants[consultantName];
            events.forEach((event) => {
              const newEvent = {
                event_id: event.evento_id,
                title: event.texto,
                start: addHours(new Date(event.data_americana), 8),
                end: addHours(new Date(event.data_americana), 9),
              };
              outputArray.push(newEvent);
            });
          }
        }
      }
    }
    return outputArray;
  };

  const fetchRemote = async (query) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const start = query.start
      .toLocaleDateString("en-US", options)
      .replace(/\//g, "-");
    const end = query.end
      .toLocaleDateString("en-US", options)
      .replace(/\//g, "-");

    return new Promise((res) => {
      axios({
        method: "get",
        url: `https://portal.gruppe.com.br/cliente/agenda/${start}/${end}`,
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
        .then((response) => {
          setScheduleData(arrangeArray(response.data.agenda));
          res(arrangeArray(response.data.agenda));
        })
        .catch((error) => {
          console.error("Error:", error);
          res(arrangeArray());
          console.error("Error:", error.response.data);
          if (error.response.data.message == "Token has expired") {
            alert(
              "Seu token expirou, por favor faça login novamente no Portal Gruppe",
            );
            localStorage.removeItem("userData");
            navigate("/login");
          }
        });
    });
  };

  const getBusinessName = (texto) => {
    const regexNomeConsultor = /^(.*?) - /;
    const matchNomeConsultor = texto.match(regexNomeConsultor);

    if (matchNomeConsultor) {
      const restanteDoTexto = texto.substring(matchNomeConsultor[0].length);
      const textoFinal = restanteDoTexto
        .replace(/\(Hora Início: \d{2}:\d{2}\)/, "")
        .trim();

      return textoFinal;
    } else {
      console.log("Texto no formato incorreto.");
    }
  };

  const getConsultantName = (texto) => {
    const regexNomeConsultor = /^(.*?) - /;
    const matchNomeConsultor = texto.match(regexNomeConsultor);

    if (matchNomeConsultor) {
      const nomeConsultor = matchNomeConsultor[1];
      return nomeConsultor;
    } else {
      console.log("Texto no formato incorreto.");
    }
  };

  const customCellClick = (scheduler) => {
    // scheduler.close()

    const dataFiltrada = scheduleData.filter((evento) => {
      const dataAlvo = new Date(scheduler.state.start.value);
      const dataEvento = new Date(evento.start);

      return dataEvento.getTime() === dataAlvo.getTime();
    });

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const ptbrDate = scheduler.state.start.value.toLocaleDateString(
      "pt-BR",
      options,
    );

    if (dataFiltrada.length > 0) {
      return (
        <div>
          <div className="custom-modal-week">
            <div className="modal-content-week">
              <div className="modal-header-content">
                <h2 style={{ marginTop: "10px" }}>Agenda {ptbrDate}</h2>
                <button onClick={scheduler.close}>Fechar</button>
              </div>
              <hr
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  border: "0",
                  borderTop: "2px solid #e5e5ea",
                }}
              />
              <div className="modal-li-week">
                {dataFiltrada.map((items, index) => {
                  return (
                    <li className="modal-items-content" key={items.title}>
                      <h2
                        style={{
                          marginBottom: "7px",
                          marginTop: "7px",
                          marginLeft: "7px",
                        }}
                      >
                        {getBusinessName(items.title)}
                      </h2>
                      <p
                        style={{
                          color: "#ffffff",
                          marginTop: "0px",
                          marginLeft: "7px",
                        }}
                      >
                        Consultor: {getConsultantName(items.title)}
                      </p>
                      {/* <p
                      style={{
                        color: "#ffffff",
                        marginTop: "0px",
                        marginLeft: "7px",
                      }}
                    >
                      Horas Totais:{" "}
                    </p> */}
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (dataFiltrada == 0) scheduler.close();
  };

  return (
    <div>
      <Scheduler
        getRemoteEvents={fetchRemote}
        editable={false}
        deletable={false}
        draggable={false}
        customEditor={(scheduler) => customCellClick(scheduler)}
        view={"month"}
        hourFormat={"24"}
        disableViewNavigator={false}
        locale={pt}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 0,
          startHour: 8,
          endHour: 13,
          navigation: true,
          disableGoToDay: true,
        }}
        week={null}
        day={null}
        translations={{
          navigation: {
            month: "Mês",
            week: "Semana",
            day: "Dia",
            today: "Hoje",
          },
          form: {
            addTitle: "Add Event",
            editTitle: "Edit Event",
            confirm: "Confirmar",
            delete: "Deletar",
            cancel: "Cancelar",
          },
          event: {
            title: "Title",
            start: "Start",
            end: "End",
            allDay: "All Day",
          },
          validation: {
            required: "Required",
            invalidEmail: "Invalid Email",
            onlyNumbers: "Only Numbers Allowed",
            min: "Minimum {{min}} letters",
            max: "Maximum {{max}} letters",
          },
          moreEvents: "Mais...",
          loading: "Loading...",
        }}
        // customViewer={(fields, event, asd) => {
        //   console.log(
        //     "🚀 ~ file: Index.jsx:207 ~ Schedule ~ asd:",
        //     event.title,
        //   );
        //   return <div>asd</div>;
        // }}
        viewerTitleComponent={(fields, event) => {
          return <div></div>;
        }}
        viewerExtraComponent={(fields, event) => {
          return (
            <div style={{ marginTop: "15px", marginBottom: "5px" }}>
              <h2 style={{ marginBottom: "5px" }}>Serviço:</h2>
              <p style={{ color: "#212121" }}>{event.title}</p>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Schedule;
