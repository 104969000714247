import { useState, useEffect } from "react";

export const MultiSelectDropdown = ({ options, selected, toggleOption }) => {
  return (
    <div className="c-multi-select-dropdown">
      <div className="c-multi-select-dropdown__selected">
        <div>
          <ins>Status</ins>
        </div>
        {/* <img src={Dropdown} /> */}
      </div>
      <ul className="c-multi-select-dropdown__options">
        {options.map((option) => {
          const isSelected = selected.includes(option.value);

          return (
            <li
              className="c-multi-select-dropdown__option"
              onClick={() => toggleOption({ value: option.value })}
            >
              <input
                type="checkbox"
                checked={isSelected}
                className="c-multi-select-dropdown__option-checkbox"
              ></input>
              <span>{option.title}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MultiSelectDropdown;
