import { Helmet as ReactHelmet } from "react-helmet";

export const Helmet = () => {
  return (
    <ReactHelmet>
      <title>Portal Gruppe</title>
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="shortcut icon"
        href={`${process.env.assetsDomain}/assets/images/favicon/cropped-favicon-192x192.ico`}
        type="image/x-icon"
      />
      <link
        rel="icon"
        href={`${process.env.assetsDomain}/assets/images/favicon/cropped-favicon-192x192.ico`}
        type="image/x-icon"
      />
      <link
        href={`${process.env.assetsDomain}/assets/css/main.css`}
        rel="stylesheet"
      />

      <meta name="theme-color" content="#ffffff" />
    </ReactHelmet>
  );
};
