import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Example } from "@components/index";

import { tokenValidation } from "@services/index.js";

const Profile = () => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });

  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? "" : navigate("/login");
  }, []);

  const user = {
    username: "john_doe",
    email: "john.doe@example.com",
    company: "Example Corp",
    selectedCompanies: ["Company A", "Company B", "Company C"],
  };

  return (
    <div className="profile">
      <h1>Perfil do Usuário</h1>
      <div className="user-info">
        <p>
          <strong>Nome:</strong> {userData.nome}
        </p>
        <p>
          <strong>Email:</strong> {userData.email}
        </p>
        {/* <p><strong>Empresa Selecionada:</strong> {user.company}</p> */}
      </div>
      {/* <h2>Selected Companies</h2>
      <ul className="selected-companies">
        {user.selectedCompanies.map((company, index) => (
          <li key={index}>{company}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default Profile;
