import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const isOnLambdaExecURL = hostname => {
  const words = hostname.split(".");
  return (
    words[1] === "execute-api" && words[3] === "amazonaws" && words[4] === "com"
  );
};

const getFirstPath = pathname => {
  const words = pathname.split("/");
  return `/${words[1]}`;
};

const location = window && window.location;

const basename =
  location && isOnLambdaExecURL(location.hostname)
    ? getFirstPath(location.pathname)
    : null;

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// ReactDOM.hydrateRoot(
//   <BrowserRouter basename={basename}>
//     <App />
//   </BrowserRouter>,
//   document.getElementById("app")
// );

// module.hot.accept();
