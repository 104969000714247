import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Example } from "@components/index";

import { tokenValidation } from "@services/index.js";

const Report = () => {
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });

  const navigate = useNavigate();

  useEffect(() => {
    tokenValidation() ? "" : navigate("/login");
  }, []);

  return (
    <div className="report">
      <h1>Relatorio</h1>
      <div className="user-info">
        <p>Relatorio page</p>
      </div>
      {/* <h2>Selected Companies</h2>
      <ul className="selected-companies">
        {user.selectedCompanies.map((company, index) => (
          <li key={index}>{company}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default Report;
